import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Button } from "@material-ui/core";
import { Form, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import config from "../../config";
import services from "../../services";
import { setBusiness } from "../../store/actions/index";
import { axiosConfig } from "../../utils/utils";

const SigninForm = ({ history, setPage }) => {
  const [login, setLogin] = useState({});
  const dispatch = useDispatch();

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const signIn = async (event) => {
    // event.preventDefault();

    services.UserServices.login(login)
      .then((response) => response.json())
      .then((response) => {
        if (response.err) {
          notify("error", response.message);
          return false;
        }
        notify("success", response.message);
        const { client, expiresIn, token } = response.data;
        if (!client?.stripeAccountId) {
          axiosConfig(
            "patch",
            `${config.apiUrl}/${
              client?.roles.includes("business_admin")
                ? "businessadmin"
                : "businessowner"
            }/${client._id}/create-connect-account`,
            null,
            token
          )
            .then((res) => {
              if (res?.data?.success) {
                window.open(res?.data?.data, "_blank", "noopener,noreferrer");
              }
              return;
            })
            .catch((err) => {
              console.error("create connect-account Error: ", err);
            });
        }
        localStorage.setItem("isAuth", true);
        localStorage.setItem("client", JSON.stringify(client));
        localStorage.setItem("expiresAt", expiresIn);
        localStorage.setItem("token", token);
        dispatch(setBusiness(client));
        if (client.roles.includes("business_admin")) {
          history.push("/app");
        }
        if (client.roles.includes("business_owner")) {
          history.push("/business-owners");
        }
        if (client.roles.includes("admin")) {
          history.push("/super-admin/dashboard");
        }
      })
      .catch((e) => console.log(e));
  };

  const RenderBack = () => {
    history.push("/");
  };
  return (
    <form action="">
      <h2 className="d-flex justify-content-between">
        Sign In
        <CloseOutlined className="closeIcon" onClick={RenderBack} />
      </h2>
      <div>
        <div className="row my-4">
          <div className="col">
            <Form
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={signIn}
              // disabled={validateLoginForm(login)}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  onChange={(event) =>
                    setLogin({
                      ...login,
                      [event.target.id]: event.target.value,
                    })
                  }
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Enter email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  type="password"
                  onChange={(event) =>
                    setLogin({
                      ...login,
                      [event.target.id]: event.target.value,
                    })
                  }
                  placeholder="Enter password"
                />
              </Form.Item>

              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  size="large"
                  fullWidth="true"
                  htmlType="submit"
                  className="login-form-button"
                  variant="contained"
                  color="primary"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <Button
            size="large"
            className="my-2"
            variant="outlined"
            color="primary"
            onClick={() => setPage("signup")}
          >
            SIGN UP AS A USER
          </Button>
          <Button
            size="large"
            className="my-2"
            variant="outlined"
            color="primary"
            onClick={() => history.push("/business-owners/signup")}
          >
            SIGN UP AS A BUSINESS OWNER
          </Button>
          <Button
            size="large"
            className="my-2"
            variant="outlined"
            color="secondary"
            onClick={() => setPage("forgot")}
          >
            FORGOT PASSWORD
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SigninForm;
