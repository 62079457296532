import React from 'react';
import NavBar from '../Constant/NavBar';

const Discovery = (props) => {
  return (
    <div>
      <NavBar prop={props}/>
      <div className="site-content-contain">
        <div id="content" className="site-content">

          <div className="wrap contactWrap">
            <div id="primary" className="content-area">
              <main id="main" className="site-main" role="main">


                <article id="post-110" className="post-110 page type-page status-publish hentry contactRow">
                  <header className="entry-header">
                    <h1 className="entry-title">Discovery Report</h1>
                    </header>
                  <div className="entry-content">
                    <div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner "><div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>This is a living report that will continue to better understand the African American gen-z consumer.</p>

                        </div>
                      </div>

                      <div className="wpb_single_image wpb_content_element vc_align_left">

                        <figure className="wpb_wrapper vc_figure">
                          <div className="vc_single_image-wrapper   vc_box_border_grey"><img src="/assets/img/hero-bg.jpg" className="w-100" /></div>
                        </figure>
                      </div>

                      <div className="wpb_single_image wpb_content_element vc_align_left">

                        <figure className="wpb_wrapper vc_figure">
                          <div className="vc_single_image-wrapper   vc_box_border_grey"><img src="/assets/img/hero-bg.jpg" className="w-100" /></div>
                        </figure>
                      </div>

                      <div className="wpb_single_image wpb_content_element vc_align_left">

                        <figure className="wpb_wrapper vc_figure">
                          <div className="vc_single_image-wrapper   vc_box_border_grey"><img src="/assets/img/hero-bg.jpg" className="w-100" /></div>
                        </figure>
                      </div>



            
                    </div></div></div></div>
                  </div>
                </article>

              </main>
            </div>
          </div>


        </div>

        <footer id="colophon" className="site-footer" role="contentinfo">
          <div className="wrap">

          </div>
        </footer>
      </div>
    </div>
  );
};

export default Discovery;