export const categories = [
  { id: 100, name: "Art" },
  { id: 'Automotive', name: "Automotive" },
  { id: "Baby and kids", name: "Baby and kids" },
  { id: 103, name: "Electronics" },
  { id: 104, name: "Entertainment" },
  { id: 105, name: "Flower Shop" },
  { id: "Food and Drink", name: "Food and Drink" },
  { id: 107, name: "Health and Beauty" },
  { id: 108, name: "Health and Fitness" },
  { id: 109, name: "Jewelry" },
  { id: 110, name: "Medical" },
  { id: 111, name: "Pet Supplies" },
  { id: 112, name: "Retail" },
  { id: 113, name: "Sports and Outdoors" },
  { id: 114, name: "Travel" },
  { id: "Other", name: "Other" }
];

export const subcategories = [
  { id: 200, name: "Auto Maintenance", categoryId: 'Automotive' },
  { id: 201, name: "Car Detailing", categoryId: 'Automotive' },
  { id: 202, name: "Transportation", categoryId: 'Automotive' },
  { id: 203, name: "Books, Movies & Music", categoryId: "Baby and kids" },
  { id: 204, name: "Car Seats", categoryId: "Baby and kids" },
  { id: 205, name: "Kids Fashion", categoryId: "Baby and kids" },
  { id: 206, name: "Strollers", categoryId: "Baby and kids" },
  { id: 207, name: "Toys", categoryId: "Baby and kids" },
  { id: 208, name: "Bars", categoryId: "Food and Drink" },
  { id: 209, name: "Cafes", categoryId: "Food and Drink" },
  { id: 210, name: "Grocery and Market", categoryId: "Food and Drink" },
  { id: 211, name: "Restaurants", categoryId: "Food and Drink" },
  { id: 212, name: "Treats ( Ice creams, Bakery )", categoryId: "Food and Drink" },
  { id: 213, name: "Winery, Brewery & Distillery", categoryId: "Food and Drink" },
  { id: 214, name: "Other", categoryId: "Other" }
];

export const useCount = [
  { id: 0, name: 0 },
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 1000, name: "unlimited" }
]