import React from 'react';
import { useDispatch } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import {  EyeOutlined } from '@ant-design/icons';

import { setCurrentCoupon } from '../../store/actions/index';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    // border: 'none',
  },
});

const CouponTable = ({ campaign, headings, setPage, pagetype }) => {
  const { coupons } = campaign;
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const setCoupon = (coupon) => {
    dispatch(setCurrentCoupon(coupon));
    if (pagetype == 'C'){
      setPage('coupon-details');
    }
    
    if (pagetype == 'P') {
      setPage('promotion-coupon-details');
    }
  };

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headings.map((head, index) => <StyledTableCell key={index} align="left">{head}</StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {coupons.map((coupon) => {
            const start_date = new Date (coupon.start_date).toUTCString();
            const expiration = new Date(coupon.expiration).toUTCString();
            return (
              <StyledTableRow hover key={coupon._id}>
                <StyledTableCell align="left">
                  {coupon.name}
                </StyledTableCell>
                <StyledTableCell align="left">
                  { moment(start_date).format("MMM Do YYYY") } - { moment(expiration).format("MMM Do YYYY") }
                </StyledTableCell>
                <StyledTableCell align="left">{coupon.status}</StyledTableCell>
                <StyledTableCell align="left">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={_ => setCoupon(coupon)}
                    className={classes.button}
                    // startIcon={<SearchIcon />}
                    startIcon={<EyeOutlined />}
                  >
                    View
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            )}
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CouponTable;
