import React from 'react';

const ThankyouPage = () => {
    return (
       <>
       <div className="thankuPage">
           <div className="container text-center">
               <h1>Thank you for Registration</h1>
               <a className="cursor-pointer">
                   <img src="./assets/img/appStore.png" width="100" />
               </a>
           </div>
       </div>
       </>
    );
};

export default ThankyouPage;