import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Form, Input, } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { validateForgotPasswordForm } from "../../resources/Validations";

const ForgotPasswordForm = ({ passwordReset, setPasswordReset, forgot, setActiveStep }) => {
  const [email, setEmail] = useState('');


  const reset = _ => {
    setPasswordReset({ ...passwordReset, email: email });
    forgot(email);
    setActiveStep(1);
  }

  return (
    <>
      <h2>Forgot Password</h2>
      <div className="row">
        <div className="col">
          <Form

            className="login-form"
            initialValues={{ remember: true }}
            onFinish={reset}
           // disabled={validateForgotPasswordForm(email)}
          >
            <Form.Item
              name="email"

              rules={[{ required: true, type: "email", message: 'Please input your Email!' }]}
            >
              <Input

                onChange={(event) => setEmail({ ...email, [event.target.id]: event.target.value })}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Enter your email"
              />
            </Form.Item>


            <Form.Item>
              <div className="row">
                <div className="col">
                  <Button
                    type="primary"
                    size="large"
                    fullWidth="true"
                    htmlType="submit"
                    className="login-form-button"
                    variant="contained"
                    color="primary"
                  >
                    Recover
                  </Button>


                </div>
              </div>

            </Form.Item>


          </Form>
          
        </div>
      </div>
    
    </>
  );
}

export default ForgotPasswordForm;