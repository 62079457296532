import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  notify: {
    margin: theme.spacing(1),
    backgroundColor: '#EEEEEE',
    padding: '20px',
    border: '1px solid #CCCCCC',
    width: '100%'
  },
  plan: {
    margin: theme.spacing(1),
    fontSize: '10px'
  }
}));

const Step5Page = ({business, setNewBusiness}) => {
  const classes = useStyles();

  return (
    <div className="row mb-3">
      <div className={classes.notify + " col"} >
        <h4>Email Notifications</h4>
        <p><small>Have your coupon performance delivered right to your email.</small></p>
        <FormControl component="fieldset">
          <RadioGroup aria-label="frequency" value={business.frequency} onChange={event => setNewBusiness({ ...business, frequency: event.target.value })}>
            <div className="row">
              <div className="col"><FormControlLabel value="none" control={<Radio />} label="None" /></div>
              <div className="col"><FormControlLabel value="daily" control={<Radio />} label="Daily" /></div>
              <div className="col"><FormControlLabel value="weekly" control={<Radio />} label="Weekly" /></div>
              <div className="col"><FormControlLabel value="monthly" control={<Radio />} label="Monthly" /></div>
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  )
}

export default Step5Page;