import config from "../config";
import AWS from 'aws-sdk';

const S3_BUCKET = config.aws_bucket_name;
const REGION = config.aws_region_name;

AWS.config.update({
    accessKeyId: config.aws_bucket_access_key_ID,
    secretAccessKey: config.aws_bucket_access_key
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

export const renameS3File = async (files, dimensions) => {
    return new Promise(async (resolve) => {

        const newUrl = [];

        for (const file of files) {

            let url = new URL(file?.images_url)
            let OLD_KEY = url.pathname;

            let NEW_KEY = url.pathname.replace(/__(\d+)-(\d+)__/, '__' + dimensions.width + "-" + dimensions.height + '__');

            newUrl.push({ images_url: "https://xclusit-dev.s3.amazonaws.com" + NEW_KEY })

            // Copy the object to a new location
            await myBucket.copyObject({
                Bucket: S3_BUCKET,
                CopySource: `/${S3_BUCKET}${OLD_KEY}`,
                Key: NEW_KEY.substring(1),
                ACL: 'public-read',
            }).promise()
                // Delete the old object
                .then(async () => {
                    await myBucket.deleteObject({
                        Bucket: S3_BUCKET,
                        Key: OLD_KEY
                    }).promise()
                        .then((res) => {
                            // console.log("res delete", res);
                        }).catch((e) => {
                            resolve({ status: false, message: e })
                        })
                })
                .catch((e) => {
                    resolve({ status: false, message: e })
                })
        }
        resolve({ status: true, message: "success", data: newUrl })
    })
}