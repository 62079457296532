import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link, TextField, Typography } from "@mui/material";
import { Home as HomeIcon } from "@material-ui/icons";
import { Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ChartSkeleton from "../../../ChartSkeleton";
import { axiosConfig, notify } from "../../../../utils/utils";
import config from "../../../../config";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BoPayoutHistoryTable from "./BoPayoutTable";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const BoPayoutHistory = ({ setPage }) => {
  const [balanceOverview, setBalanceOverview] = useState({});
  const [paymentHistory, setPaymentHistory] = useState({});
  const [isLoading, setIsLoading] = useState(true); // loading for balance overview
  const [historyLoading, setHistoryLoading] = useState(false); // loading for payment history
  const [withdrawLoading, setWithdrawLoading] = useState(false); // loading for withdrawal submission
  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
  const [isBankVerified, setIsBankVerified] = useState(false); // bank account verification status
  const [verificationLink, setVerificationLink] = useState(""); // link for verification if needed
  const [showVerificationUI, setShowVerificationUI] = useState(false); // state to control showing the verification UI
  const [showNoBankAccount, setShowNoBankAccount] = useState(false);

  const classes = useStyles();

  const LoginBAData = JSON.parse(localStorage.getItem("client"));
  const accessToken = localStorage.getItem("token");

  // Fetch payment history (called if bank is verified)
  const fetchPaymentHistory = async () => {
    setHistoryLoading(true); // start history loading
    try {
      const res = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner/${LoginBAData._id}/list-payout`,
        null,
        accessToken
      );
      setPaymentHistory(res?.data?.data);
    } catch (err) {
      console.error("list-payout error: ", err);
    } finally {
      setHistoryLoading(false); // end history loading
      setIsLoading(false);
    }
  };

  // Fetch balance overview and account verification status
  const fetchBalanceOverview = async () => {
    setIsLoading(true); // Start loading for balance overview

    // Attempt to fetch balance overview
    try {
      const res = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner/${LoginBAData._id}/get-stripe-balance-overview`,
        null,
        accessToken
      );

      if (res?.data?.success) {
        const isVerified =
          res?.data?.stripeAccountDetails?.charges_enabled &&
          res?.data?.stripeAccountDetails?.details_submitted &&
          res?.data?.stripeAccountDetails?.payouts_enabled;

        setIsBankVerified(isVerified);
        setBalanceOverview(res?.data?.data);

        if (isVerified) {
          await fetchPaymentHistory(); // Fetch payment history if bank is verified
          return; // Exit after successful fetching
        }
      }
    } catch (err) {
      // Check if the error is "Stripe account not found"
      if (err?.response?.data?.error?.message === "Stripe account not found") {
        setShowNoBankAccount(true); // Show "No Bank Account Found" UI
      } else {
        console.error("Error fetching balance overview:", err);
        notify("error", err?.response?.data?.error?.message);
      }
    }

    // If the balance overview is not successful, try to fetch the verification link
    try {
      const res = await axiosConfig(
        "patch",
        `${config.apiUrl}/businessowner/${LoginBAData._id}/create-connect-account`,
        null,
        accessToken
      );

      if (res?.data?.success) {
        setVerificationLink(res?.data?.data);
        setShowVerificationUI(true); // Show verification UI if successful
      }
    } catch (err) {
      console.error("Error fetching connect account verification link:", err);
      notify("error", err?.response?.data?.error?.message);
    } finally {
      setIsLoading(false); // Stop balance loading
    }
  };

  useEffect(() => {
    fetchBalanceOverview();
  }, []);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("withdraw amount is required!")
      .min(1, "amount is not valid!")
      .test("max-available", function (value) {
        if (balanceOverview?.available === 0) {
          return this.createError({
            message: `Can't withdraw, available balance is $${balanceOverview?.available}`,
          });
        }
        return (
          value <= balanceOverview?.available ||
          this.createError({
            message: `Amount must be less than or equal to $${balanceOverview?.available}`,
          })
        );
      }),
  });

  const handleOnSubmit = async (fields) => {
    setWithdrawLoading(true); // start withdrawal loading
    try {
      const res = await axiosConfig(
        "post",
        `${config.apiUrl}/businessowner/${LoginBAData._id}/create-payout`,
        fields,
        accessToken
      );
      if (res?.data?.success) {
        fetchBalanceOverview(); // refresh balance and payment history after withdrawal
      }
    } catch (err) {
      console.error("create payout error: ", err);
    } finally {
      setWithdrawLoading(false); // end withdrawal loading
      setIsWithdrawOpen(false); // close modal
    }
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      initialValues: { amount: "" },
      validationSchema,
      onSubmit: (values, action) => {
        handleOnSubmit(values);
        action.resetForm();
      },
    });

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" onClick={() => setPage("dashboard")}>
            <HomeIcon />
          </Link>
          <Typography color="textPrimary">Payout History</Typography>
        </Breadcrumbs>
        {isBankVerified && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsWithdrawOpen(true)}
          >
            Withdraw
          </Button>
        )}
      </div>

      {/* Display loading spinner while fetching balance overview */}
      <div>
        {isLoading ? (
          <div
            className="my-3 p-3 rounded-lg text-center"
            style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
          >
            <Spin indicator={<LoadingOutlined spin />} />
          </div>
        ) : showNoBankAccount ? (
          <div
            className="my-3 p-3 rounded-lg"
            style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <strong className="text-danger">No Bank Account Found!</strong>
            </div>
          </div>
        ) : showVerificationUI ? (
          <div
            className="my-3 p-3 rounded-lg text-center"
            style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
          >
            <h5 className="font-weight-bold text-center">
              <ErrorOutlineIcon
                fontSize="large"
                style={{ width: 60, height: 60 }}
              />
            </h5>
            <div className="d-flex align-items-center justify-content-center h4 font-weight-bolder">
              Your Stripe Account is not verified yet!
            </div>
            <div className="text-center">
              <p>
                To start your way to creating and selling products on the
                XCLUSIT app.
              </p>
              <p>
                Please verify your Stripe account!{" "}
                <a
                  href={verificationLink}
                  className="font-weight-bold"
                  style={{ textDecoration: "underline" }}
                >
                  click here!
                </a>
              </p>
              <b>Thank you for being a part of the XCLUSIT brand.</b>
            </div>
          </div>
        ) : (
          // Payment history and balance overview UI
          <div>
            <>
              <Grid container spacing={2} className="my-3">
                {Object.keys(balanceOverview)?.map((item, index) => (
                  <Grid item key={index} md={3} xs={12} className="box">
                    <Card className={classes.root}>
                      <CardHeader
                        title={item}
                        className="text-center"
                        style={{ textTransform: "capitalize" }}
                      />
                      <CardContent>
                        <Typography
                          variant="h2"
                          color="textSecondary"
                          component="h2"
                          className="text-center"
                          style={{ fontSize: "25px", fontWeight: "600" }}
                        >
                          $
                          {typeof balanceOverview?.[item] === "number"
                            ? balanceOverview?.[item]?.toFixed(2)
                            : ""}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {/* Show payment history loading spinner */}
              {historyLoading ? (
                <div className="text-center my-3">
                  <Spin indicator={<LoadingOutlined spin />} />
                </div>
              ) : (
                <BoPayoutHistoryTable
                  headings={["AMOUNT", "ARRIVAL DATE", "METHOD", "STATUS"]}
                  data={paymentHistory}
                  historyLoading={historyLoading}
                />
              )}
            </>
          </div>
        )}
      </div>

      {/* {isLoading ? (
        <div className="text-center my-3">
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      ) : showVerificationUI ? (
        <div
          className="my-3 p-3 rounded-lg"
          style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
        >
          <h5 className="font-weight-bold text-center">
            <ErrorOutlineIcon
              fontSize="large"
              style={{ width: 60, height: 60 }}
            />
          </h5>
          <div className="d-flex align-items-center justify-content-center h4 font-weight-bolder">
            Your Stripe Account is not verified yet!
          </div>
          <div className="text-center">
            To verify your Stripe account, please{" "}
            <a href={verificationLink}>click here!</a>
          </div>
        </div>
      ) : isBankVerified ? (
        
      ) : (
        <div
          className="my-3 p-3 rounded-lg"
          style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
        >
          {historyLoading ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : paymentHistory?.bankAccounts?.length ? (
            <div className="d-flex align-items-center justify-content-between">
              <strong className="text-danger">No Bank Account Found!</strong>
            </div>
          ) : null}
        </div>
      )} */}

      <Modal
        centered
        show={isWithdrawOpen}
        onHide={() => setIsWithdrawOpen(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Cash</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="number"
              id="amount"
              name="amount"
              label="Amount"
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.amount && Boolean(errors.amount)}
              helperText={touched.amount && errors.amount}
            />
            <div
              className="my-3 p-3 rounded-lg"
              style={{
                borderLeft: "7px solid rgba(63, 81, 181, 1)",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
            >
              <h5 className="font-weight-bold">Bank Details</h5>
              {historyLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : paymentHistory?.bankAccounts?.length ? (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-1 text-muted">Bank Name:</p>
                    <p className="mb-1 font-weight-bold">
                      {paymentHistory?.bankAccounts?.[0]?.bank_name ?? "N/A"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-1 text-muted">Account Holder Name:</p>
                    <p className="mb-1 font-weight-bold">
                      {paymentHistory?.bankAccounts?.[0]?.account_holder_name ??
                        "N/A"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-1 text-muted">Account:</p>
                    <p className="mb-1 font-weight-bold">
                      {paymentHistory?.bankAccounts?.[0]?.account ?? "N/A"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-1 text-muted">Account Type:</p>
                    <p className="mb-1 font-weight-bold">
                      {paymentHistory?.bankAccounts?.[0]?.account_type ?? "N/A"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 text-muted">Card:</p>
                    <p className="mb-0 font-weight-bold">
                      {paymentHistory?.bankAccounts?.[0]?.last4
                        ? `XXXX XXXX XXXX ${paymentHistory?.bankAccounts?.[0]?.last4}`
                        : "N/A"}
                    </p>
                  </div>
                </>
              ) : (
                <strong className="text-danger">No Bank Account Found!</strong>
              )}
            </div>
            <div className="modal-footer">
              <Button variant="contained" color="primary" type="submit">
                {withdrawLoading ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BoPayoutHistory;
