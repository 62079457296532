import React from 'react';
import { Button } from '@material-ui/core';

import { validatePasswordReset } from "../../resources/Validations";
import { Form, Input, } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const VerificationReset = ({ passwordReset, setPasswordReset, resetPassword }) => {
 

  // if(passwordReset.password != passwordReset.new_password){
  //   alert("did not match")
  // }

  const handlesubmit = () => {
    //    if(passwordReset.password != passwordReset.new_password){
    //    alert("did not match")
    //  }
    //  else{
    //    alert("sdfsdf")
    //  }
  }
  return (
    <form action="">
      <h2>Reset Password</h2>
      <div className="">

        <Form

          className="login-form"
          initialValues={{ remember: true }}
          onFinish={resetPassword}
       //   disabled={validatePasswordReset(passwordReset)}
        >
          <Form.Item
            name="code"

            rules={[{ required: true, message: 'Please fill  your code!' }]}
          >
            <Input
              name="text"
              onChange={(event) => setPasswordReset({ ...passwordReset, [event.target.id]: event.target.value })}
              placeholder="Enter your verification code"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your Password!' },
              { min: 8, message: 'enter must be minimum 8 characters.' }
            ]}
          >
            <Input.Password
              // maxLength={8}
              type="password"
              onChange={(event) => setPasswordReset({ ...passwordReset, [event.target.id]: event.target.value })}
              placeholder="Enter New Password"
            />
          </Form.Item>

          <Form.Item
            name="new_password"
            rules={[
              { required: true, message: 'Please Confirm New Password!' },
              { min: 8, message: 'enter must be minimum 8 characters.' }

            ]}
          >
            <Input.Password
              // maxLength={9}
              min='8'
              type="password"
              onChange={(event) => setPasswordReset({ ...passwordReset, [event.target.id]: event.target.value })}
              placeholder="Confirm New Password"
            />
          </Form.Item>

          <Form.Item>
            <div className="row mb-4">
              <div className="col">


                <Button
                  type="primary"
                  size="large"
                  fullWidth="true"
                  htmlType="submit"
                  className="login-form-button"
                  variant="contained"
                  color="primary"
                  onClick={handlesubmit}
                >
                  Recover
                </Button>


              </div>
            </div>

          </Form.Item>


        </Form>
        {/* <div className="row mb-4">
            <div className="col">
              <input
                className="form-control"
                type="text"
                id="code"
                onChange={(event) => setPasswordReset({ ...passwordReset, [event.target.id]: event.target.value })}
                placeholder="Enter your verification code" />
            </div>
          </div> */}
        {/* <div className="row mb-4">
            <div className="col">
              <input
                className="form-control"
                type="password"
                id="password"
                autoComplete="false"
                onChange={(event) => setPasswordReset({ ...passwordReset, [event.target.id]: event.target.value })}
                placeholder="Enter New Password" />
            </div>
          </div> */}
        {/* <div className="row mb-4">
            <div className="col">
              <input
                className="form-control"
                type="password"
                id="new_password"
                autoComplete="false"
                onChange={(event) => setPasswordReset({ ...passwordReset, [event.target.id]: event.target.value })}
                placeholder="Confirm New Password" />
            </div>
          </div> */}
        {/* <div className="row mb-4">
            <div className="col">
              <Button
                size="large"
                disabled={validatePasswordReset(passwordReset)}
                variant="contained"
                color="primary"
                onClick={() => resetPassword()}>
                Recover
              </Button>
            </div>
          </div> */}
      </div>
    </form>
  );
}

export default VerificationReset;