import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/images/CoupifyLogo-2.png";
import config from "../config";
import CampaignDetails from "../pages/Campaign/CampaignDetails";
import Campaigns from "../pages/Campaign/Campaigns";
import CouponDetails from "../pages/Coupon/CouponDetails";
import Dashboard from "../pages/Dashboard/Dashboard";
import Followers from "../pages/Dashboard/Followers";
import Invoice from "../pages/Invoice/Invoice";
import InvoiceHistory from "../pages/Invoice/InvoiceHistory";
import Profile from "../pages/Profile/Profile";
import PromotionsDetails from "../pages/Promotion/PromotionDetails";
import Promotions from "../pages/Promotion/Promotions";
import RevenueBa from "../pages/RevenueBa/RevenueBa";
import services from "../services";
import { setBusiness, setCategory } from "../store/actions";
import { axiosConfig, birthdateValidation, notify } from "../utils/utils";
import NavBar from "./Constant/NavBar";
import MenuList from "./Menu";
import OrderDetails from "./Orders/OrderDetails";
import Orders from "./Orders/Orders";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import PaymentHistory from "./PayoutHistory/PayoutHistory";
import SubscriptionPlan from "./Plan/SubscriptionPlan";
import ProductCampaigns from "./ProductCampaigns/ProductCampaigns";
import ProductCouponDetails from "./ProductCampaigns/ProductCouponDetails";
import Requests from "./Requests/Requests";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 1040,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 1040,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  coupifyLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Layout = (props) => {
  const LoginBAData = JSON.parse(localStorage.getItem("client"));

  const { loadPage, history } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { client } = useSelector((state) => state.client);
  const [page, setPage] = useState(loadPage);
  const [open, setOpen] = useState(true);
  const [customerId, setCustomerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [adminDOB, setAdminDOB] = useState({});
  const [showBirthDate, setShowBirthDate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLegacyUser, setIsLegacyUser] = useState(false);

  const noPlan = true;

  const dispatch = useDispatch();

  const logout = () => {
    notify("success", "Logout Successfully");
    dispatch(setBusiness({}));
    setIsLegacyUser(false);
    setPaymentStatus({});
    localStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    const LegacyUserAccess = () => {
      const date1 = LoginBAData?.created_at?.split("T")[0];
      const date2 = process.env.REACT_APP_LEGACY_DATE;
      const clientDate = new Date(date1);
      const tillDate = new Date(date2);
      if (clientDate < tillDate) {
        // console.log("clientDate is before tillDate");
        return true;
      } else {
        // console.log("clientDate is after tillDate");
        return false;
      }
    };
    setIsLegacyUser(LegacyUserAccess());
  }, [LoginBAData]);

  useEffect(() => {
    if (!LoginBAData?.birthday) {
      setShowBirthDate(true);
      return;
    }
    setShowBirthDate(false);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 767.9) {
      setOpen(false);
    }
  }, []);

  const getCategory = () => {
    services.UserServices.getCategory()
      .then((res) => {
        dispatch(setCategory(res?.data?.data?.categoryList));
      })
      .catch((err) => console.error("getCategory error", err));
  };

  useEffect(() => {
    if (!client._id) {
      history.push("/");
    } else {
    }
  });

  useEffect(() => {
    getCategory();
  }, []);

  const getAllBusinessOwner = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessadmin/${LoginBAData._id}/check-payment-status`,
        null,
        accessToken
      );
      setPaymentStatus(response?.data?.data);
    } catch (error) {
      console.log("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getActiveSubScription = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessadmin/${LoginBAData._id}/get-active-subscription`,
        null,
        accessToken
      );
      setCustomerId(response?.data?.data?.stripeId);
    } catch (error) {
      console.log("fetch active subscription error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBusinessOwner();
    getActiveSubScription();
  }, [page]);

  const handleBirthDateAdd = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
      return;
    }
    const payload = {
      birthday: adminDOB,
    };
    services.UserServices.updateProfile(LoginBAData?._id, payload)
      .then((res) => {
        if (res?.data?.err) return false;
        setShowBirthDate(false);
        notify("success", "BirthDate added successfully");
        localStorage.removeItem("client");
        setTimeout(() => {
          localStorage.setItem(
            "client",
            JSON.stringify(res?.data?.data?.client_data)
          );
        }, 100);
      })
      .catch((e) => console.error("edit profile error: ", e));
  };

  const PageLoad = () => {
    // Check if the page is restricted and if paymentStatus.isActivePlan is false
    if (!!paymentStatus?.isActivePlan || isLegacyUser || noPlan) {  // TODO: remove noPlan if not needed in future
      switch (page) {
        case "campaigns":
          return <Campaigns {...props} page={page} setPage={setPage} />;
        case "promotions":
          return <Promotions {...props} page={page} setPage={setPage} />;
        case "ProductCampaign":
          return <ProductCampaigns {...props} page={page} setPage={setPage} />;
        case "Orders":
          return <Orders {...props} page={page} setPage={setPage} />;
        case "Orders-details":
          return <OrderDetails {...props} page={page} setPage={setPage} />;
        case "Requests":
          return <Requests {...props} page={page} setPage={setPage} />;
        case "campaign-details":
          return <CampaignDetails {...props} page={page} setPage={setPage} />;
        case "promotion-details":
          return <PromotionsDetails {...props} page={page} setPage={setPage} />;
        case "campaign-add":
          return <CampaignAdd {...props} page={page} setPage={setPage} />;
        case "coupon-details":
          return (
            <CouponDetails
              {...props}
              page={page}
              setPage={setPage}
              loadtype={"C"}
            />
          );
        case "promotion-coupon-details":
          return (
            <CouponDetails
              {...props}
              page={page}
              setPage={setPage}
              loadtype={"P"}
            />
          );
        case "product-coupon-details":
          return (
            <ProductCouponDetails {...props} page={page} setPage={setPage} />
          );
        case "invoices":
          return <Invoice {...props} page={page} setPage={setPage} />;
        case "followers":
          return <Followers {...props} page={page} setPage={setPage} />;
        case "revenue":
          return (
            <RevenueBa
              {...props}
              page={page}
              setPage={setPage}
              SubscriptionPlanName={paymentStatus}
            />
          );
        case "plan":
          return (
            <SubscriptionPlan
              {...props}
              page={page}
              setPage={setPage}
              setCustomerId={setCustomerId}
            />
          );
        case "payment_method":
          return (
            <PaymentMethods
              {...props}
              page={page}
              setPage={setPage}
              customerId={customerId}
            />
          );
        case "payout_history":
          return <PaymentHistory {...props} page={page} setPage={setPage} />;
        case "navbar":
          return <NavBar {...props} page={page} setPage={setPage} />;
        case "invoice-history":
          return <InvoiceHistory {...props} page={page} setPage={setPage} />;
        case "profile":
          return <Profile {...props} page={page} setPage={setPage} />;
        default:
          return <Dashboard {...props} page={page} setPage={setPage} />;
      }
    } else {
      switch (page) {
        case "plan":
          return (
            <SubscriptionPlan
              {...props}
              page={page}
              setPage={setPage}
              setCustomerId={setCustomerId}
            />
          );
        case "payment_method":
          return (
            <PaymentMethods
              {...props}
              page={page}
              setPage={setPage}
              customerId={customerId}
            />
          );
        case "payout_history":
          return <PaymentHistory {...props} page={page} setPage={setPage} />;
        default:
          return (
            <PaymentMethods
              {...props}
              page={page}
              setPage={setPage}
              customerId={customerId}
            />
          );
      }
    }
  };

  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="white"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="headerStyle">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpenClose}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className="p-0">
            {client.client_name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          <div className={classes.coupifyLogo + " col"}>
            <img src={logo} alt="" width="100" className="cooppyLogo" />
          </div>
          <div className={classes.toolbar + " col-1"} style={{ padding: "0" }}>
            <IconButton
              onClick={handleDrawerOpenClose}
              style={{ padding: "0" }}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        </div>
        <Divider />
        <MenuList
          {...props}
          page={page}
          setPage={setPage}
          paymentStatus={paymentStatus}
          isLegacyUser={isLegacyUser}
          noPlan={noPlan}
          logout={logout}
        />
      </Drawer>
      <main className={classes.content + " mainContent"}>
        <div className={classes.toolbar} />
        {PageLoad()}
      </main>

      {/* birthday add modal */}
      <Modal
        show={showBirthDate}
        onHide={() => setShowBirthDate(false)}
        size="lg"
        centered
        className="edit_businessTag_modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>BUSINESS BIRTHDATE</Modal.Title>
          {/* <Modal.Title
            onClick={() => setShowBirthDate(false)}
            style={{ cursor: "pointer" }}
          >
            x
          </Modal.Title> */}
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleBirthDateAdd}>
          <div className="modal-body">
            <Form.Group>
              <Form.Label>BIRTHDATE</Form.Label>
              <input
                className="form-control"
                style={{ height: "48px" }}
                type="date"
                max={birthdateValidation()}
                value={adminDOB}
                onChange={(e) => setAdminDOB(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the birthdate!
              </Form.Control.Feedback>
            </Form.Group>

            <p className="text-muted">
              <strong className="text-dark">Note: </strong>
              To complete your profile, please provide your birthdate. This
              information helps us offer a more personalized experience.
            </p>
          </div>
          <div className="modal-footer">
            <Button type="submit" variant="primary">
              Save
            </Button>
            {/* <Button
            variant="outline-dark"
            onClick={() => setShowBirthDate(false)}
          >
            Cancel
          </Button> */}
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Layout;
