import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const ChartSkeleton = _ => {
  return (
    <div>
      <Skeleton variant="text" width={200} height={40} />
      <Skeleton variant="text" width={300} height={30} />
      <Skeleton variant="rect" className="text-center" width={118} height={118} />
    </div>
  );
}

export default ChartSkeleton;
