import axios from "axios";
import { toast } from "react-toastify";

const LoginBAData = JSON.parse(localStorage.getItem("client"));

export const notify = (type, text) =>
  toast[type](text, {
    position: toast.POSITION.TOP_RIGHT,
  });

export const returnDaysOptionList = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
];

export const colorOptions = [
  { label: "green", value: "green" },
  { label: "yellow", value: "yellow" },
  { label: "red", value: "red" },
  { label: "white", value: "white" },
  { label: "black", value: "black" },
  { label: "pink", value: "pink" },
];

export const sizeOptions = [
  { label: "XS", value: "XS" },
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "2XL", value: "2XL" },
  { label: "3XL", value: "3XL" },
];

export const orderStatus = [
  { label: "In Process", value: "In Process", color: "warning" },
  { label: "Shipped", value: "Shipped", color: "primary" },
  { label: "Delivered", value: "Delivered", color: "success" },
  { label: "Returned", value: "Returned", color: "error" },
];

export const requestStatus = [
  { label: "Rejected", value: "Rejected", color: "error" },
  { label: "Approved", value: "Approved", color: "success" },
];

export const validatePrice = /^(0?\.[0-9]|[1-9][0-9]*(\.[0-9]+)?)$/;
// export const validatePrice = /^(?!0$)([1-9]\d*|\d+\.\d*[1-9]|\d+)?$/;
// export const validatePrice = /^(([0]{0,}[1-9]{1,})+|([.]{1}[1-9]{1,}))+|/g;
// export const validatePrice = /^-?\d*\.?\d*$/;

export const formatRevenue = (num, fixed) => {
  if (num >= 1e9) {
    return (num / 1e9)?.toFixed(1) + "B";
  } else if (num >= 1e6) {
    return (num / 1e6)?.toFixed(1) + "M";
  } else if (num >= 1e3) {
    return (num / 1e3)?.toFixed(1) + "K";
  } else {
    return num?.toFixed(fixed);
  }
};

export const axiosConfig = (method, url, data, auth) => {
  return axios({
    method: method,
    url: url,
    data: data,
    headers: {
      Authorization: auth,
    },
  });
};

export const addonCategory = () => {
  return [
    {
      label: "Addon Category",
      name: "mainvariantname",
      value: "",
      propertyType: "text",
      isRequired: true,
    },
    {
      label: "Addon Category Required",
      name: "mendatory",
      value: false,
      propertyType: "checkbox",
      isRequired: false,
    },
    {
      label: "Is Paid",
      name: "ispaid",
      value: false,
      propertyType: "checkbox",
      isRequired: false,
    },
    {
      label: "Multi choice",
      name: "ismultichoice",
      value: false,
      propertyType: "checkbox",
      isRequired: false,
    },
    {
      label: "Select up to",
      name: "maxselection",
      value: "",
      propertyType: "number",
      isRequired: true,
    },
  ];
};

export const deliveryAddressFormat = (address) => {
  if (!address) return "N/A";

  const addressComponents = [
    address.line1 || "",
    address.line2 || "",
    address.city || "",
    address.state || "",
    address.country || "",
    address.postalCode || "",
  ];

  const formattedAddress = addressComponents.join(" ").trim();
  return formattedAddress ? formattedAddress : "N/A";
};

export const birthdateValidation = () => {
  const today = new Date();
  const minValidDate = new Date();
  minValidDate.setFullYear(today.getFullYear() - 18);

  // Format the date as 'YYYY-MM-DD'
  const minDateFormatted = minValidDate.toISOString().split("T")[0];
  return minDateFormatted;
};
