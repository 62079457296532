import React from "react";
import { Link } from "react-router-dom";

const NavBar = ({ setPage }) => {
  return (
    <>
      <header id="masthead" className="site-header" role="banner">
        <div className="custom-header">
          <div className="custom-header-media"></div>
          <div className="site-branding">
            <div className="wrap">
              <Link
                to="/"
                className="custom-logo-link"
                rel="home"
                aria-current="page"
              >
                <img
                  width="250"
                  height="250"
                  src="/assets/img/logo.png"
                  className="custom-logo"
                  alt=""
                  sizes="100vw"
                />
              </Link>
              <div className="site-branding-text">
                <h1 className="site-title">
                  <Link to="/" rel="home"></Link>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="navigation-top">
          <div className="wrap">
            <nav
              id="site-navigation"
              className="main-navigation"
              role="navigation"
              aria-label="Top Menu"
            >
              <button
                className="menu-toggle"
                aria-controls="top-menu"
                aria-expanded="false"
              >
                <svg className="icon icon-bars" aria-hidden="true" role="img">
                  {" "}
                  <use href="#icon-bars"></use>{" "}
                </svg>
                <svg className="icon icon-close" aria-hidden="true" role="img">
                  {" "}
                  <use href="#icon-close"></use>{" "}
                </svg>
                Menu{" "}
              </button>
              <div className="menu-xclusit-container">
                <ul id="top-menu" className="menu d-flex flex-wrap">
                  <li
                    id="menu-item-40"
                    className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-40"
                  >
                    <a href="https://xclusit.com/index.html" className="hm">
                      Home
                    </a>
                  </li>
                  <li
                    id="menu-item-135"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-135"
                  >
                    <a href="https://xclusit.com/contact.html" className="hm">
                      Contact
                    </a>
                  </li>
                  <li
                    id="menu-item-137"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-137"
                  >
                    <a href="https://xclusit.com/creators.html" className="hm">
                      Creators
                    </a>
                  </li>
                  <li
                    id="menu-item-137"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-137"
                  >
                    <a href="https://xclusit.com/terms.html" className="hm">
                      Terms
                    </a>
                  </li>
                  <li
                    id="menu-item-137"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-137"
                  >
                    <a href="https://xclusit.com/privacy.html" className="hm">
                      Privacy
                    </a>
                  </li>
                  <li
                    id="menu-item-137"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-137 ml-auto"
                  >
                    <Link to="/auth" className="hm">
                      Sign in/Sign up
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavBar;
